import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PoolPublicationTypeModel} from '../../../../../models/requests/pool/pool-publication-type.model';

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.scss']
})
export class ProposalSummaryComponent implements OnInit {
  isLoading = true;

  @Input() public stage1: any;
  @Input() public stage2: any;
  @Input() public stage3: any;
  @Input() public stage4: any;
  @Input() public stage5: any;
  @Input() public files: any[];
  @Input() public pools: Observable<any>;

  private poolNames = new Map<number, string>();
  private publicationTypes = new Map<string, PoolPublicationTypeModel>();

  constructor() {
  }

  ngOnInit(): void {
    this.pools.subscribe(pools => {
      pools.forEach(pool => {
        this.poolNames.set(pool.id, pool.name);

        pool.publicationTypes.forEach(publicationType => {
          this.publicationTypes.set(pool.id + '-' + publicationType.id, {
            pool: pool.id,
            name: publicationType.name,
            maximumAmountAwards: publicationType.maximumAmountAwards
          });
        });
      });
      this.isLoading = false;
    });
  }

  getMaximumAmount(poolId: number, publicationTypeId?: number): number {
    const publisherMemberAssociation = this.stage3.publisher[0].memberAssociation;
    const publisherContractConcluded = this.stage3.publisher[0].contractConcluded;

    const authorMemberAssociation = this.stage3.authors[0].memberAssociation;

    if (null === publicationTypeId) {
      return 0;
    }
    const publicationTypeKey = poolId + '-' + publicationTypeId;
    if (!this.publicationTypes.has(publicationTypeKey)) {
      return 0;
    }

    const maximumAmountForType = this.publicationTypes.get(publicationTypeKey).maximumAmountAwards;

    if (true === publisherMemberAssociation && true === publisherContractConcluded) {
      return maximumAmountForType;
    } else if (true === publisherMemberAssociation || true === publisherContractConcluded) {
      return maximumAmountForType * 0.5;
    } else if (true === authorMemberAssociation) {
      return maximumAmountForType * 0.25;
    } else {
      return 0;
    }
  }

  getPoolNameById(poolId: number): string {
    return this.poolNames.get(poolId);
  }

  getPublicationTypeNameById(poolId: number, publicationTypeId?: number): string {
    if (publicationTypeId !== null) {
      const key = poolId + '-' + publicationTypeId;
      if (!this.publicationTypes.has(key)) {
        return '-';
      }
      return this.publicationTypes.get(key).name;
    }
    return publicationTypeId?.toString() || '-';
  }
}
