<form class="pool-upload" [formGroup]="poolForm" (ngSubmit)="onFormSubmit(poolForm)">
  <mat-form-field class="pool-input">
    <mat-label>Nazwa puli</mat-label>
    <input formControlName="name" aria-label="pool name" matInput
           placeholder="np. Dofinansowanie książek kryminalnych - edycja 2020"
           type="text">
  </mat-form-field>
  <h2>Warunki</h2>
  <mat-form-field *ngFor="let condition of poolConditions.controls; let i=index" class="pool-input" [formGroup]="condition">
    <mat-label>Warunek</mat-label>
    <input (change)="managePoolConditions()" formControlName="name" aria-label="pool condition" matInput
           placeholder="np. Nakład większy niż 30000 kopii" type="text">
    <hr />
  </mat-form-field>
  <mat-hint *ngIf="poolConditions.errors && poolConditions.touched" [class.error]="true">
    Musi być conajmniej jeden
  </mat-hint>
  <h2>Typy publikacji</h2>
  <ng-container *ngFor="let publicationType of poolPublicationTypes.controls; let i=index" [formGroup]="publicationType">
    <mat-form-field class="pool-input">
      <mat-label>Typ</mat-label>
      <input (change)="managePoolPublicationTypes()" formControlName="name" aria-label="pool publication type" matInput
             placeholder="np. Kryminał" type="text">
    </mat-form-field>
    <mat-form-field class="pool-input">
      <mat-label> Maksymalna kwota wynagrodzenia</mat-label>
      <input (change)="managePoolPublicationTypes()" formControlName="maximumAmountAwards" aria-label="pool publication type" matInput
             placeholder="np. 30000" type="number">
    </mat-form-field>
    <hr />
  </ng-container>
  <mat-hint *ngIf="poolPublicationTypes.errors && poolPublicationTypes.touched" [class.error]="true">
    Musi być conajmniej jeden
  </mat-hint>
  <h2>Pozostałe informacje</h2>
  <p>
    Maksymalne dofinansowanie dla tej puli: {{ poolForm.get('maximumAmountAwards').value }}
  </p>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>Data rozpoczęcia</mat-label>
      <input formControlName="startDate" [matDatepicker]="startPicker" aria-label="start date" matInput>
      <mat-datepicker-toggle [for]="startPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Data zakończenia</mat-label>
      <input formControlName="endDate" [matDatepicker]="endPicker" aria-label="end date" matInput>
      <mat-datepicker-toggle [for]="endPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <button [disabled]="fileToUpload !== null" color="primary" mat-raised-button type="submit">
    Wyślij
  </button>
</form>
