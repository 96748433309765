import {FormGroup} from '@angular/forms';

export class FormUtils {

  public static markAllFormFieldsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
