import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PoolManageComponent} from '../pool-manage/pool-manage.component';
import {PoolUploadComponent} from '../pool-upload/pool-upload.component';
import {PoolListService} from './pool-list.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pool-list',
  templateUrl: './pool-list.component.html',
  styleUrls: ['./pool-list.component.scss']
})
export class PoolListComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'name',
    'maximumAmountAwards',
    'startDate',
    'endDate',
    'status',
    'user',
    'createdAt',
    'options'
  ];
  public pools;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    public poolAgreementService: PoolListService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.poolAgreementService.refreshPoolList();
    this.poolAgreementService.pools.subscribe(data => {
      this.pools = new MatTableDataSource(data);
      this.pools.paginator = this.paginator;
      this.pools.sort = this.sort;
    });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pools.filter = filterValue.trim().toLowerCase();

    if (this.pools.paginator) {
      this.pools.paginator.firstPage();
    }
  }

  public openDialog(id: number) {
    const dialogRef = this.dialog.open(PoolManageComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '600px',
      panelClass: 'block-panel',
      data: {
        poolId: id
      },
    });
  }

  public createNewPoolAgreement() {
    const dialogRef = this.dialog.open(PoolUploadComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public openProposalsFilteredByPool(pool = null) {
    this.router.navigate(['admin/co-financing/proposal'], {queryParams: {pool}});
  }
}
