<div class="proposal-preview">
  <div class="row space-between">
    <app-summary-field name="Pula, do której składany jest wniosek">
      {{ proposal.pool.name }}
    </app-summary-field>
    <button mat-stroked-button color="primary" (click)="getPreviewFile()" [disabled]="isPreviewProposalLoading">
      Pobierz podgląd <mat-icon>description</mat-icon>
    </button>
  </div>

  <mat-divider class="big-divider"></mat-divider>

  <h1>Dane wnioskodawców</h1>
  <div class="row">
    <app-summary-field name="Typ publikacji">
      {{proposal.poolPublicationType.name}}
    </app-summary-field>
    <app-summary-field name="Kwota dofinansowania (brutto)">
      {{proposal.amountFunding}}
    </app-summary-field>
    <app-summary-field name="Tytuł publikacji">
      {{proposal.publishTitle}}
    </app-summary-field>
    <app-summary-field name="ISBN">
      {{proposal.isbn}}
    </app-summary-field>
  </div>

  <mat-divider class="medium-divider"></mat-divider>

  <ng-container *ngFor="let publisher of proposal.publishers; let i=index">
    <h2>Wydawca</h2>
    <div class="row">
      <app-summary-field name="Nazwa wydawcy">
        {{publisher.publisher_name}}
      </app-summary-field>
      <app-summary-field name="E-mail">
        {{publisher.email}}
      </app-summary-field>
      <app-summary-field name="Numer telefonu">
        {{publisher.phone}}
      </app-summary-field>
      <app-summary-field name="Ulica, numer/mieszkanie">
        {{publisher.street}}
      </app-summary-field>
      <app-summary-field name="Kod pocztowy">
        {{publisher.post_code}}
      </app-summary-field>
      <app-summary-field name="Miejscowość">
        {{publisher.city}}
      </app-summary-field>
      <app-summary-field name="Kraj">
        {{publisher.country}}
      </app-summary-field>
      <app-summary-field name="NIP">
        {{publisher.publisher_nip}}
      </app-summary-field>
      <app-summary-field name="Numer KRS/CEIDG">
        {{publisher.krs_ceidg}}
      </app-summary-field>
      <app-summary-field name="Jestem płatnikiem VAT">
        {{ publisher.is_vat_payer | booleanToString }}
      </app-summary-field>
    </div>
    <div class="row" *ngFor="let membership of publisher.memberships; let j=index">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{ membership.is_member_association | booleanToString }}
      </app-summary-field>
      <app-summary-field name="Zawarłem ze Stowarzyszeniem umowę o zbiorowe zarządzanie">
        {{ membership.is_contract_concluded | booleanToString }}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <ng-container *ngFor="let author of proposal.authors; let i=index">
    <h2>Autor {{i+1}}</h2>
    <div class="row">
      <app-summary-field name="Imię">
        {{author.name}}
      </app-summary-field>
      <app-summary-field name="Nazwisko">
        {{author.lastname}}
      </app-summary-field>
      <app-summary-field name="Pseudonim">
        {{author.nickname}}
      </app-summary-field>
      <app-summary-field name="E-mail">
        {{author.email}}
      </app-summary-field>
      <app-summary-field name="Numer telefonu">
        {{author.phone}}
      </app-summary-field>
      <app-summary-field name="Pesel">
        {{author.pesel}}
      </app-summary-field>
      <app-summary-field name="Ulica, numer/mieszkanie">
        {{author.street}}
      </app-summary-field>
      <app-summary-field name="Kod pocztowy">
        {{author.post_code}}
      </app-summary-field>
      <app-summary-field name="Miejscowość">
        {{author.city}}
      </app-summary-field>
      <app-summary-field name="Kraj">
        {{author.country}}
      </app-summary-field>
    </div>
    <div class="row" *ngFor="let membership of author.memberships; let j=index">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{ membership.is_member_association | booleanToString }}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Członkostwo</h1>
  <ng-container *ngFor="let publisher of proposal.publishers; let i=index">
    <h2>Wydawca</h2>
    <div class="row" *ngIf="getPublisherMembership(i); let membership">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{membership.is_member_association | booleanToString}}
      </app-summary-field>
      <app-summary-field name="Zawarłem ze Stowarzyszeniem umowę o zbiorowe zarządzanie">
        {{membership.is_contract_concluded | booleanToString}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <ng-container *ngFor="let author of proposal.authors; let i=index">
    <h2>Autor {{i+1}}</h2>
    <div class="row" *ngIf="getAuthorMembership(i); let membership">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{membership.is_member_association | booleanToString}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Opis działalności</h1>
  <div class="row">
    <app-summary-field name="Opis działalności twórczej wnioskodawcy - autora">
      {{proposal.descriptionCreativeActivityApplicant}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>

  <h1>Dotychczasowe publikacje wydawcy - przykładowe</h1>
  <ng-container *ngFor="let previousPublisherPublication of proposal.previousPublisherPublications; let i=index">
    <app-admin-publication-preview [publicationNumber]="i+1" [publication]="previousPublisherPublication"></app-admin-publication-preview>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Dotychczasowe publikacje twórcy - przykładowe</h1>
  <ng-container *ngFor="let previousAuthorPublication of proposal.previousAuthorPublications; let i=index">
    <app-admin-publication-preview [publicationNumber]="i+1" [publication]="previousAuthorPublication"></app-admin-publication-preview>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h2>Opis i uzasadnienie wydania publikacji</h2>
  <div class="row">
    <app-summary-field name="Opis publikacji wraz ze streszczeniem">
      {{proposal.descriptionPublication}}
    </app-summary-field>
    <app-summary-field name="Uzasadnienie konieczności wydania tej publikacji">
      {{proposal.justificationPublishing}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>
  <h1>Kosztorys publikacji</h1>
  <ng-container *ngFor="let publicationCost of proposal.publicationCosts; let i=index">
    <div class="row">
      <app-summary-field name="Autor">
        {{publicationCost.author}}
      </app-summary-field>
      <app-summary-field name="Tytuł">
        {{publicationCost.title}}
      </app-summary-field>
      <app-summary-field name="Wydawca">
        {{publicationCost.publisher}}
      </app-summary-field>
      <app-summary-field name="Data wydania">
        {{publicationCost.publish_date}}
      </app-summary-field>
      <app-summary-field name="ISBN">
        {{publicationCost.isbn_publication}}
      </app-summary-field>
    </div>
    <div class="row">
      <app-summary-field name="Kwota dofinansowania (brutto)">
        {{proposal.amountFunding}}
      </app-summary-field>
      <app-summary-field name="Objętość w arkuszach wydawniczych">
        {{publicationCost.publishing_sheets}}
      </app-summary-field>
      <app-summary-field name="Liczba stron">
        {{publicationCost.number_pages}}
      </app-summary-field>
      <app-summary-field name="Liczba ilustracji">
        {{publicationCost.number_illustrations}}
      </app-summary-field>
      <app-summary-field name="Forma książki">
        {{publicationCost.book_form | bookFormToText}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
    <div class="row">
      <app-summary-field name="Przygotowanie do druku, druk">
        {{publicationCost.preparation_for_printing}}
      </app-summary-field>
      <app-summary-field name="Koszty przygotowania do druku (brutto)">
        {{publicationCost.printing_cost}}
      </app-summary-field>
      <app-summary-field name="Koszty wydawcy (brutto)">
        {{publicationCost.publisher_cost}}
      </app-summary-field>
      <app-summary-field name="Wynagrodzenie autorskie (brutto)">
        {{publicationCost.copyright_fee}}
      </app-summary-field>
      <app-summary-field name="Cena detaliczna">
        {{publicationCost.retail_price}}
      </app-summary-field>
      <app-summary-field name="Nakład druku">
        {{publicationCost.print_run}}
      </app-summary-field>
      <app-summary-field name="Oświadczam, że publikacja jest ukończona">
        {{publicationCost.is_publication_finished | booleanToString}}
      </app-summary-field>
    </div>
  </ng-container>

  <mat-divider class="big-divider"></mat-divider>

  <h1>Dokumenty do wgrania</h1>
  <div class="file-wrapper">
    <ng-container *ngFor="let file of proposal.files">
      <app-file-download [fileId]="file.id" [fileName]="file.name"></app-file-download>
    </ng-container>
    <ng-container *ngIf="proposal.files.length === 0">
      brak
    </ng-container>
  </div>
</div>
