import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bookFormToText'})
export class BookFormToText implements PipeTransform {

  transform(value?: string): string {
    if (null === value) {
      return '';
    }
    return value
      .toString()
      .replace('1', 'Druk')
      .replace('2', 'E-book')
      .replace(',', ', ');
  }
}
