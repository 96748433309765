import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {IsbnValidator} from '../../../../validators/isbnValidator';
import {NipValidator} from '../../../../validators/nipValidator';
import {PeselValidator} from '../../../../validators/peselValidator';
import {PhoneValidator} from '../../../../validators/phoneValidator';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-proposal-view',
  templateUrl: './proposal-view.component.html',
  styleUrls: ['./proposal-view.component.scss']
})
export class ProposalViewComponent implements OnInit {
  public isLoading;
  public proposalId: number;
  public proposal = null;
  public stage1: FormGroup = this.formBuilder.group({
    pool: [null, Validators.required],
  });
  public stage2: FormGroup = this.formBuilder.group({
    id: [null],
    poolPublicationType: [null, Validators.required],
    amountFunding: [null, Validators.required],
    publishTitle: [null, Validators.required],
    isbn: [null, [Validators.required, IsbnValidator()]],
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([])
  });
  public stage3: FormGroup = this.formBuilder.group({
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([]),
  });
  public stage4: FormGroup = this.formBuilder.group({
    id: [null],
    authorPublishings: this.formBuilder.array([]),
    publisherPublishings: this.formBuilder.array([]),
    descriptionPublication: [null, Validators.required],
    justificationPublishing: [null, Validators.required],
    descriptionCreativeActivityApplicant: [null, Validators.required],
  });
  public stage5: FormGroup = this.formBuilder.group({
    id: [null],
    author: [null, Validators.required],
    title: [null, Validators.required],
    publisher: [null, Validators.required],
    publishDate: [null, Validators.required],
    amountFunding: [null, Validators.required],
    publishingSheets: [null, Validators.required],
    numberPages: [null, Validators.required],
    numberIllustrations: [null, Validators.required],
    isbnPublication: [null, [Validators.required, IsbnValidator()]],
    bookForm: [null, Validators.required],
    printingCost: [null, Validators.required],
    preparationForPrinting: [null, Validators.required],
    publisherCost: [null, Validators.required],
    copyrightFee: [null, Validators.required],
    retailPrice: [null, Validators.required],
    printRun: [null, Validators.required],
    isPublicationFinished: [null, Validators.required],
  });

  public files = [];
  private poolObserver: any = new Subject();
  public pools = this.poolObserver.asObservable();

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.proposalId = Number(route.snapshot.paramMap.get('id'));
  }

  get authors(): FormArray {
    return this.stage2.get('authors') as FormArray;
  }

  get publishers(): FormArray {
    return this.stage2.get('publisher') as FormArray;
  }

  get authorsMembership(): FormArray {
    return this.stage3.get('authors') as FormArray;
  }

  get publisherMembership(): FormArray {
    return this.stage3.get('publisher') as FormArray;
  }

  get authorPublishings(): FormArray {
    return this.stage4.get('authorPublishings') as FormArray;
  }

  get publisherPublishings(): FormArray {
    return this.stage4.get('publisherPublishings') as FormArray;
  }

  ngOnInit(): void {
    this.loadProposal(this.proposalId);
  }

  private loadProposal(proposalId: number) {
    this.isLoading = true;
    this.apiService.getProposalByPublisher(proposalId).subscribe((response: any) => {
      this.isLoading = false;
      this.proposal = response.data;
      this.files = response.data.files;

      this.stage1.patchValue(
        {
          pool: this.proposal.pool.id
        }
      );
      this.stage2.patchValue(this.proposal);
      this.stage2.patchValue({
        poolPublicationType: this.proposal.poolPublicationType?.id
      });
      this.stage3.patchValue(this.proposal);
      this.stage4.patchValue(this.proposal);

      this.proposal.authors.forEach(item => {
        this.addAuthor(item);
      });
      this.proposal.publishers.forEach(item => {
        this.addPublisher(item);
      });
      this.proposal.previousPublisherPublications.forEach(item => {
        this.addPublisherPublishings(item);
      });
      this.proposal.previousAuthorPublications.forEach(item => {
        this.addAuthorPublishings(item);
      });

      if (this.proposal.publicationCosts.length === 1) {
        const publicationCost = this.proposal.publicationCosts[0];
        this.stage5.patchValue(publicationCost);
        this.stage5.patchValue({
          publishDate: publicationCost.publish_date,
          amountFunding: publicationCost.amount_funding,
          publishingSheets: publicationCost.publishing_sheets,
          numberPages: publicationCost.number_pages,
          numberIllustrations: publicationCost.number_illustrations,
          isbnPublication: publicationCost.isbn_publication,
          bookForm: publicationCost.book_form,
          printingCost: publicationCost.printing_cost,
          preparationForPrinting: publicationCost.preparation_for_printing,
          publisherCost: publicationCost.publisher_cost,
          copyrightFee: publicationCost.copyright_fee,
          retailPrice: publicationCost.retail_price,
          printRun: publicationCost.print_run,
          isPublicationFinished: publicationCost.is_publication_finished,
        });
      }

      this.loadPools();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public buildAuthor(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      lastname: [null, Validators.required],
      nickname: [null],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, PhoneValidator()]],
      pesel: [null, [Validators.required, PeselValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  public buildPublisher(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, PhoneValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      nip: [null, [Validators.required, NipValidator()]],
      krsceidg: [null, Validators.required],
    });
  }

  public buildAuthorMembership(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      memberAssociation: [false, Validators.required],
    });
  }

  public buildPublisherMembership(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      memberAssociation: [false, Validators.required],
      contractConcluded: [false, Validators.required],
    });
  }

  public buildAuthorPublishings(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
      debutantWithoutLiteraryAchievements: [false, Validators.required],
    });
  }

  public buildPublisherPublishings(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
    });
  }

  addAuthor(author = null): void {
    this.addAuthorMembership(author);
    const form = this.buildAuthor();
    if (author != null) {
      form.patchValue(author);
      form.patchValue({
        postcode: author.post_code,
      });
    }

    this.authors.push(form);
  }

  addPublisher(publisher = null): void {
    this.addPublisherMembership(publisher);
    const form = this.buildPublisher();
    if (publisher !== null) {
      form.patchValue(publisher);
      form.patchValue({
        name: publisher.publisher_name,
        postcode: publisher.post_code,
        nip: publisher.publisher_nip,
        krsceidg: publisher.krs_ceidg,
      });
    }

    this.publishers.push(form);
  }

  addAuthorMembership(author = null): void {

    const form = this.buildAuthorMembership();
    if (author != null && author.memberships.length > 0) {
      const membership = author.memberships[0];
      form.patchValue(membership);
      form.patchValue({
        memberAssociation: membership.is_member_association,
      });
    }
    this.authorsMembership.push(form);
  }

  addPublisherMembership(publisher = null): void {
    const form = this.buildPublisherMembership();
    if (publisher != null) {
      const membership = publisher.memberships[0];
      form.patchValue(membership);
      form.patchValue({
        memberAssociation: membership.is_member_association,
        contractConcluded: membership.is_contract_concluded,
      });
    }
    this.publisherMembership.push(form);
  }

  addAuthorPublishings(publication = null): void {
    const form = this.buildAuthorPublishings();
    if (publication != null) {
      form.patchValue(publication);
      form.patchValue({
        publishDate: publication.publish_date,
        roleInPublication: publication.role_in_publication,
        reportedPlr: publication.is_reported_plr,
        reportedSubContract: publication.is_reported_sub_contract,
        reportedMembershipDeclaration: publication.is_reported_membership_declaration,
        debutantWithoutLiteraryAchievements: publication.is_debutant_without_literary_achievements,
      });
    }
    this.authorPublishings.push(form);
  }

  addPublisherPublishings(publication = null): void {
    const form = this.buildPublisherPublishings();
    if (publication != null) {
      form.patchValue(publication);
      form.patchValue({
        publishDate: publication.publish_date,
        roleInPublication: publication.role_in_publication,
        reportedPlr: publication.is_reported_plr,
        reportedSubContract: publication.is_reported_sub_contract,
        reportedMembershipDeclaration: publication.is_reported_membership_declaration,
      });
    }
    this.publisherPublishings.push(form);
  }

  public loadPools() {
    this.isLoading = true;
    this.apiService.getAcceptedPools().subscribe((response: any) => {
      this.isLoading = false;
      this.poolObserver.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
