<div class="proposal-summary" *ngIf="!isLoading">
  <div class="row">
    <app-summary-field name="Pula, do której składany jest wniosek">
      {{getPoolNameById(stage1.pool)}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>

  <h1>Dane wnioskodawców</h1>
  <div class="row">
    <app-summary-field name="Typ publikacji">
      {{getPublicationTypeNameById(stage1.pool, stage2.poolPublicationType)}}
    </app-summary-field>
    <app-summary-field name="Maksymalna kwota dofinansowania (brutto)">
      {{getMaximumAmount(stage1.pool, stage2.poolPublicationType)}}
    </app-summary-field>
    <app-summary-field name="Tytuł publikacji">
      {{stage2.publishTitle}}
    </app-summary-field>
    <app-summary-field name="ISBN">
      {{stage2.isbn}}
    </app-summary-field>
  </div>
  <mat-divider class="medium-divider"></mat-divider>
  <ng-container *ngFor="let publisher of stage2.publisher; let i=index">
    <h2>Wydawca</h2>
    <div class="row">
      <app-summary-field name="Nazwa wydawcy">
        {{publisher.name}}
      </app-summary-field>
      <app-summary-field name="E-mail">
        {{publisher.email}}
      </app-summary-field>
      <app-summary-field name="Numer telefonu">
        {{publisher.phone}}
      </app-summary-field>
      <app-summary-field name="Ulica, numer/mieszkanie">
        {{publisher.street}}
      </app-summary-field>
      <app-summary-field name="Kod pocztowy">
        {{publisher.postcode}}
      </app-summary-field>
      <app-summary-field name="Miejscowość">
        {{publisher.city}}
      </app-summary-field>
      <app-summary-field name="Kraj">
        {{publisher.country}}
      </app-summary-field>
      <app-summary-field name="NIP">
        {{publisher.nip}}
      </app-summary-field>
      <app-summary-field name="Numer KRS/CEIDG">
        {{publisher.krsceidg}}
      </app-summary-field>
      <app-summary-field name="Jestem płatnikiem VAT">
        {{publisher.isVatPayer | booleanToString}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>
  <ng-container *ngFor="let author of stage2.authors; let i=index">
    <h2>Autor {{i+1}}</h2>
    <div class="row">
      <app-summary-field name="Imię">
        {{author.name}}
      </app-summary-field>
      <app-summary-field name="Nazwisko">
        {{author.lastname}}
      </app-summary-field>
      <app-summary-field name="Pseudonim">
        {{author.nickname}}
      </app-summary-field>
      <app-summary-field name="E-mail">
        {{author.email}}
      </app-summary-field>
      <app-summary-field name="Numer telefonu">
        {{author.phone}}
      </app-summary-field>
      <app-summary-field name="Pesel">
        {{author.pesel}}
      </app-summary-field>
      <app-summary-field name="Ulica, numer/mieszkanie">
        {{author.street}}
      </app-summary-field>
      <app-summary-field name="Kod pocztowy">
        {{author.postcode}}
      </app-summary-field>
      <app-summary-field name="Miejscowość">
        {{author.city}}
      </app-summary-field>
      <app-summary-field name="Kraj">
        {{author.country}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Członkostwo</h1>
  <ng-container *ngFor="let publisher of stage3.publisher; let i=index">
    <h2>Wydawca</h2>
    <div class="row">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{publisher.memberAssociation | booleanToString}}
      </app-summary-field>
      <app-summary-field name="Zawarłem ze Stowarzyszeniem umowę o zbiorowe zarządzanie">
        {{publisher.contractConcluded | booleanToString}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>
  <ng-container *ngFor="let author of stage3.authors; let i=index">
    <h2>Autor {{i+1}}</h2>
    <div class="row">
      <app-summary-field name="Jestem członkiem Stowarzyszenia">
        {{author.memberAssociation | booleanToString}}
      </app-summary-field>
    </div>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Opis działalności</h1>
  <div class="row">
    <app-summary-field name="Opis działalności twórczej wnioskodawcy - autora">
      {{stage4.descriptionCreativeActivityApplicant}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>

  <h1>Dotychczasowe publikacje wydawcy - przykładowe</h1>
  <ng-container *ngFor="let publishing of stage4.publisherPublishings; let i=index">
    <app-proposal-summary-publication [publicationNumber]="i+1" [publishing]="publishing"></app-proposal-summary-publication>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h1>Dotychczasowe publikacje twórcy - przykładowe</h1>
  <ng-container *ngFor="let publishing of stage4.authorPublishings; let i=index">
    <app-proposal-summary-publication [publicationNumber]="i+1" [publishing]="publishing"></app-proposal-summary-publication>
    <mat-divider class="medium-divider"></mat-divider>
  </ng-container>

  <h2>Opis i uzasadnienie wydania publikacji</h2>
  <div class="row">
    <app-summary-field name="Opis publikacji wraz ze streszczeniem">
      {{stage4.descriptionPublication}}
    </app-summary-field>
  </div>
  <div class="row">
    <app-summary-field name="Uzasadnienie konieczności wydania tej publikacji">
      {{stage4.justificationPublishing}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>
  <h1>Kosztorys publikacji</h1>
  <div class="row">
    <!--
    <app-summary-field name="Autor">
      {{stage5.author}}
    </app-summary-field>
    <app-summary-field name="Tytuł">
      {{stage5.title}}
    </app-summary-field>
    <app-summary-field name="Wydawca">
      {{stage5.publisher}}
    </app-summary-field>
    -->
    <app-summary-field name="Data wydania">
      {{stage5.publishDate}}
    </app-summary-field>
    <app-summary-field name="Wnioskowana kwota dofinansowania (brutto)">
      {{stage5.amountFunding}}
    </app-summary-field>
    <!--
    <app-summary-field name="ISBN">
      {{stage5.isbnPublication}}
    </app-summary-field>
    -->
  </div>
  <div class="row">
    <!--
    <app-summary-field name="Kwota dofinansowania (brutto)">
      {{stage5.amountFunding}}
    </app-summary-field>
    -->
    <app-summary-field name="Objętość w arkuszach wydawniczych">
      {{stage5.publishingSheets}}
    </app-summary-field>
    <app-summary-field name="Liczba stron">
      {{stage5.numberPages}}
    </app-summary-field>
    <app-summary-field name="Liczba ilustracji">
      {{stage5.numberIllustrations}}
    </app-summary-field>
    <app-summary-field name="Forma książki">
      {{stage5.bookForm | bookFormToText}}
    </app-summary-field>
  </div>
  <mat-divider class="medium-divider"></mat-divider>
  <div class="row">
    <app-summary-field name="Przygotowanie do druku, druk">
      {{stage5.preparationForPrinting}}
    </app-summary-field>
    <app-summary-field name="Koszty przygotowania do druku (brutto)">
      {{stage5.printingCost}}
    </app-summary-field>
    <app-summary-field name="Koszty wydawcy (brutto)">
      {{stage5.publisherCost}}
    </app-summary-field>
    <app-summary-field name="Wynagrodzenie autorskie (brutto)">
      {{stage5.copyrightFee}}
    </app-summary-field>
    <app-summary-field name="Cena detaliczna">
      {{stage5.retailPrice}}
    </app-summary-field>
    <app-summary-field name="Nakład druku">
      {{stage5.printRun}}
    </app-summary-field>
  </div>
  <div class="row">
    <app-summary-field name="Oświadczam, że publikacja jest ukończona">
      {{stage5.publicationFinished | booleanToString}}
    </app-summary-field>
  </div>

  <mat-divider class="big-divider"></mat-divider>
  <h1>Dokumenty do wgrania</h1>
  <div class="file-wrapper">
    <ng-container *ngFor="let file of files">
      <app-file-download [fileId]="file.id" [fileName]="file.name"></app-file-download>
    </ng-container>
  </div>
  <ng-container *ngIf="files.length === 0">
    brak
  </ng-container>
</div>
<div class="loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
