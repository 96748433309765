import {Component, Inject, Input, OnInit} from '@angular/core';
import {PoolModel} from '../../../../models/requests/pool/pool.model';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PoolListService} from '../pool-list/pool-list.service';
import {ErrorService} from '../../../../services/error.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../services/FormUtils';

@Component({
  selector: 'app-pool-upload',
  templateUrl: './pool-upload.component.html',
  styleUrls: ['./pool-upload.component.scss']
})
export class PoolUploadComponent implements OnInit {
  @Input() public pool?: PoolModel = null;

  public poolForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.minLength(1), Validators.required]],
    poolConditions: this.formBuilder.array([], Validators.minLength(2)),
    poolPublicationTypes: this.formBuilder.array([], Validators.minLength(2)),
    maximumAmountAwards: [0, Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });
  public fileToUpload: File = null;

  get poolConditions() {
    return this.poolForm.get('poolConditions') as FormArray;
  }

  get poolPublicationTypes() {
    return this.poolForm.get('poolPublicationTypes') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private dialogRef: MatDialog,
    private poolAgreementService: PoolListService,
    public formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    if (null !== this.pool) {
      this.poolForm.patchValue(this.pool);
      this.pool.poolConditions.forEach(item => {
        this.addCondition(item);
      });
      this.pool.poolPublicationTypes.forEach(item => {
        this.addPublicationType(item);
      });
    }
    this.managePoolConditions();
    this.managePoolPublicationTypes();
  }

  private addCondition(condition = null): void {
    const form = this.buildCondition();
    if (condition != null) {
      form.patchValue(condition);
    }

    this.poolConditions.push(form);
  }

  private addPublicationType(publicationType = null): void {
    const form = this.buildPublicationType();
    if (publicationType != null) {
      form.patchValue(publicationType);
    }

    this.poolPublicationTypes.push(form);
  }

  public managePoolConditions() {
    const indexes = [];
    for (let x = 0; x < this.poolConditions.length; x++) {
      if (this.poolConditions.at(x).get('name').value === '') {
        indexes.push(x);
      } else {
        this.poolConditions.at(x).get('name').setValidators([Validators.required]);
      }
    }

    indexes.reverse();
    indexes.forEach(index => {
      this.poolConditions.removeAt(index);
    });

    this.addCondition();
  }

  public managePoolPublicationTypes() {
    const indexes = [];
    let sumMaximumAmountAwards = 0;
    for (let x = 0; x < this.poolPublicationTypes.length; x++) {
      if (this.poolPublicationTypes.at(x).get('name').value === '') {
        indexes.push(x);
      } else {
        this.poolPublicationTypes.at(x).get('name').setValidators([Validators.required]);
        this.poolPublicationTypes.at(x).get('maximumAmountAwards').setValidators([Validators.required, Validators.min(1)]);
        this.poolPublicationTypes.at(x).get('maximumAmountAwards').updateValueAndValidity();
      }
      const maximumAmountAwards = Number(this.poolPublicationTypes.at(x).get('maximumAmountAwards').value);
      if (maximumAmountAwards) {
        sumMaximumAmountAwards += maximumAmountAwards;
      }
    }
    this.poolForm.get('maximumAmountAwards').setValue(sumMaximumAmountAwards);

    indexes.reverse();
    indexes.forEach(index => {
      this.poolPublicationTypes.removeAt(index);
    });

    this.addPublicationType();
  }

  public buildCondition(): FormGroup {
    return this.formBuilder.group({
      name: [''],
    });
  }

  public buildPublicationType(): FormGroup {
    return this.formBuilder.group({
      name: [''],
      maximumAmountAwards: [0],
    });
  }

  public onFormSubmit(form: FormGroup) {
    form.updateValueAndValidity();
    if (!form.valid) {
      FormUtils.markAllFormFieldsAsTouched(this.poolForm);
      return;
    }

    const requestPool = form.value;
    requestPool.maximumAmountAwards = requestPool.maximumAmountAwards.toString();
    requestPool.poolConditions.pop();
    requestPool.poolPublicationTypes.map(item => ({
      name: item.name,
      maximumAmountAwards: item.maximumAmountAwards.toString()
    })).pop();

    if (null === this.pool) {
      this.apiService.postPool(requestPool).subscribe((response: any) => {
        this.sweetalertService.success();
        this.poolAgreementService.refreshPoolList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
        if (error.status === 400) {
          // error.validationErrors.
        }
        this.managePoolConditions();
        this.managePoolPublicationTypes();
        FormUtils.markAllFormFieldsAsTouched(this.poolForm);
      });
    } else {
      this.apiService.updatePool(this.pool.id, requestPool).subscribe((response: any) => {
        this.sweetalertService.success();
        this.poolAgreementService.refreshPoolList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
        this.managePoolConditions();
        this.managePoolPublicationTypes();
        FormUtils.markAllFormFieldsAsTouched(this.poolForm);
      });
    }
  }
}
